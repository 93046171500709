import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import router from './router'

import VueCountryCode from "vue-country-code-select";

Vue.use(VueCountryCode);

const firebaseConfig = {
  apiKey: "AIzaSyDkVrR-64mHOVNTntMAf8I2EoD29NWDyFI",
  authDomain: "ticket-shop-27610.firebaseapp.com",
  projectId: "ticket-shop-27610",
  storageBucket: "ticket-shop-27610.appspot.com",
  messagingSenderId: "192336892575",
  appId: "1:192336892575:web:86c07c9e8cf033169308aa"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
